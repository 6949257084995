/**
 * Created by methee on 9/13/17.
 */

import {
  ADD_FLASH_MESSAGE,
  REMOVE_FLASH_MESSAGE,
  SHOW_OVERLAY_ONLY,
  CONFIRM_FLASH_MESSAGE,
  DISMISS_FLASH_MESSAGE,
} from '../constants';

import { TYPE_ONLY_OVERLAY } from '../actions/flashMessage';

export default function flashMessage(state = null, action) {
  switch (action.type) {
    case ADD_FLASH_MESSAGE: {
      const { message, type } = action.payload;
      return {
        ...state,
        message,
        type,
        confirm: false,
        dismissed: false,
      };
    }
    case REMOVE_FLASH_MESSAGE: {
      const message = '';
      const type = '';
      return {
        ...state,
        message,
        type,
        confirm: false,
      };
    }
    case DISMISS_FLASH_MESSAGE: {
      return {
        ...state,
        dismissed: true,
      };
    }
    case CONFIRM_FLASH_MESSAGE: {
      return {
        ...state,
        confirm: true,
      };
    }
    case SHOW_OVERLAY_ONLY: {
      const message = '';
      const type = TYPE_ONLY_OVERLAY;
      return {
        ...state,
        message,
        type,
        confirm: false,
      };
    }
    default: {
      return state;
    }
  }
}
