import { combineReducers } from 'redux';
import user from './user';
import runtime from './runtime';
import intl from './intl';
import booking from './booking';
import flashMessage from './flashMessage';
import checkIn from './check-in';
import hotel from './hotel';
import common from './common';

export default function createRootReducer({ apolloClient }) {
  return combineReducers({
    apollo: apolloClient.reducer(),
    user,
    runtime,
    intl,
    hotel,
    booking,
    checkIn,
    flashMessage,
    common,
  });
}
