import { OPEN_MEETINGROOM_MODAL, CLOSE_MEETINGROOM_MODAL } from '../constants';

export default function common(
  state = {
    link: null,
    openMeetingRoomModal: false,
  },
  action,
) {
  switch (action.type) {
    case OPEN_MEETINGROOM_MODAL: {
      return {
        ...state,
        openMeetingRoomModal: true,
        link: action.payload,
      };
    }
    case CLOSE_MEETINGROOM_MODAL: {
      return {
        ...state,
        openMeetingRoomModal: false,
        link: null,
      };
    }
    default:
      return state;
  }
}
