/* eslint-disable object-shorthand */
/* eslint-disable camelcase, no-param-reassign */

import {
  CHECK_IN_FAIL_CREDIT_CARD,
  CHECK_IN_PAYMENT_START,
  CHECK_IN_PAYMENT_FAIL,
  CHECK_IN_PAYMENT_SUCCESS,
  CHECK_IN_SUCCESS_CREDIT_CARD,
  CHECK_IN_VERIFY_DOCUMENT,
  CHECK_IN_ROOM_PREFERENCES,
  FAIL_START_CHECK_IN,
  START_CHECK_IN,
  CHECK_IN_EDIT_GUEST_SUCCESS,
  CHECK_IN_EDIT_GUEST_FAIL,
  CHECK_IN_UPLOADID_SUCCESS,
  CHECK_IN_UPLOADID_FAIL,
} from '../constants';

export default function checkIn(state = null, action) {
  switch (action.type) {
    case START_CHECK_IN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FAIL_START_CHECK_IN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CHECK_IN_SUCCESS_CREDIT_CARD: {
      const creditCard = action.payload;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        creditCard,
        errors,
        errorMessage,
      };
    }
    case CHECK_IN_FAIL_CREDIT_CARD: {
      const errors = action.payload;
      return {
        ...state,
        errors,
      };
    }
    case CHECK_IN_PAYMENT_START: {
      return {
        ...state,
        isSubmitting: true,
        errors: [],
        errorMessage: '',
      };
    }
    case CHECK_IN_PAYMENT_SUCCESS: {
      const {
        payment_merchant_name,
        paid_at,
        card_type,
        suffix_card_number,
        total,
        creditCard,
        redirect_3ds,
      } = action.payload;
      return {
        ...state,
        booking: {
          ...state.booking,
          payment_merchant_name,
          paid_at,
          card_type,
          suffix_card_number,
          total,
        },
        creditCard,
        isSubmitting: false,
        redirect_3ds,
        errors: [],
        errorMessage: '',
      };
    }
    case CHECK_IN_PAYMENT_FAIL: {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        errorMessage,
        errors,
        isSubmitting: false,
      };
    }
    case CHECK_IN_VERIFY_DOCUMENT: {
      return {
        ...state,
        idVerificationAPI: {
          ...action.payload,
        },
      };
    }
    case CHECK_IN_ROOM_PREFERENCES: {
      return {
        ...state,
        roomPreferences: {
          ...action.payload,
        },
      };
    }
    case CHECK_IN_EDIT_GUEST_SUCCESS: {
      const { first_name, last_name, phoneCountry, email } = action.payload;
      const {
        booking: { rooms },
      } = state;
      rooms.forEach(room => {
        room.guests.forEach(guest => {
          guest.first_name = first_name;
          guest.last_name = last_name;
          guest.phoneCountry = phoneCountry;
          guest.email = email;
        });
      });
      return {
        ...state,
        booking: {
          ...state.booking,
          rooms,
        },
      };
    }
    case CHECK_IN_EDIT_GUEST_FAIL: {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        errorMessage,
        errors,
      };
    }
    case CHECK_IN_UPLOADID_SUCCESS: {
      const { idReferenceNumber, fileName, imageUrl } = action.payload;
      return {
        ...state,
        idReferenceFileName: fileName,
        idReferenceNumber: idReferenceNumber,
        imageUrl: imageUrl,
      };
    }
    case CHECK_IN_UPLOADID_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        errorMessage,
      };
    }
    default: {
      return state;
    }
  }
}
