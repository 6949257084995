/* eslint-disable global-require */
import { CLEAR_HOTEL_INFO } from '../constants';

// The top-level (parent) route
const routes = {
  path: '/:locale(en|th|ja|id|ko|zh-hans|zh-hant)?',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/search',
      load: () => import(/* webpackChunkName: 'search' */ './search'),
    },
    {
      path: '/search/:hotel',
      load: () => import(/* webpackChunkName: 'hotel' */ './hotel'),
    },
    {
      path: '/country/:country',
      load: () => import(/* webpackChunkName: 'country' */ './country'),
    },
    {
      path: '/city/:city',
      load: () => import(/* webpackChunkName: 'city' */ './city'),
    },
    {
      path: '/booking/room-select',
      load: () => import(/* webpackChunkName: 'room-select' */ './room-select'),
    },
    {
      path: '/booking/edit/room-select',
      load: () =>
        import(/* webpackChunkName: 'edit-room-select' */ './edit-room-select'),
    },
    {
      path: '/booking/guest-profile',
      load: () =>
        import(/* webpackChunkName: 'guest-profile' */ './guest-profile'),
    },
    {
      path: '/booking/payment',
      load: () => import(/* webpackChunkName: 'payment' */ './payment'),
    },
    {
      path: '/booking/confirmation',
      load: () =>
        import(
          /* webpackChunkName: 'booking-confirmation' */ './booking-confirmation'
        ),
    },
    {
      path: '/booking/redirect-to-issuer',
      load: () =>
        import(
          /* webpackChunkName: 'redirect-to-issuer' */ './redirect-to-issuer'
        ),
    },
    {
      path: '/booking/confirm',
      load: () =>
        import(
          /* webpackChunkName: 'payment-confirmation' */ './payment-confirmation'
        ),
    },
    {
      path: '/hotels',
      load: () => import(/* webpackChunkName: 'hotels' */ './hotels'),
    },
    {
      path: '/hotel/:hotel',
      load: () => import(/* webpackChunkName: 'hotel' */ './hotel'),
    },
    {
      path: '/sign-in',
      load: () => import(/* webpackChunkName: 'sign-in' */ './sign-in'),
    },
    {
      path: '/sign-up',
      load: () => import(/* webpackChunkName: 'sign-up' */ './sign-up'),
    },
    {
      path: '/account',
      load: () => import(/* webpackChunkName: 'account' */ './account'),
    },
    {
      path: '/conditions',
      load: () => import(/* webpackChunkName: 'conditions' */ './conditions'),
    },
    {
      path: '/policy',
      load: () => import(/* webpackChunkName: 'policy' */ './policy'),
    },
    {
      path: '/terms',
      load: () => import(/* webpackChunkName: 'terms' */ './terms'),
    },
    {
      path: '/cookies',
      load: () => import(/* webpackChunkName: 'cookies' */ './cookies'),
    },
    {
      path: '/cctv',
      load: () => import(/* webpackChunkName: 'cctv' */ './cctv'),
    },
    {
      path: '/faq',
      load: () => import(/* webpackChunkName: 'faqs' */ './faqs'),
    },
    {
      path: '/travel-updates',
      load: () => import(/* webpackChunkName: 'covid' */ './covid'),
    },
    {
      path: '/vouchers',
      load: () => import(/* webpackChunkName: 'vouchers' */ './vouchers'),
    },
    {
      path: '/red-app',
      load: () => import(/* webpackChunkName: 'red-app' */ './red-app'),
    },
    {
      path: '/promotion/:code',
      load: () => import(/* webpackChunkName: 'promotion' */ './promotion'),
    },
    {
      path: '/forgot-password',
      load: () =>
        import(/* webpackChunkName: 'forgot-password' */ './forgot-password'),
    },
    {
      path: '/reset_password/:token',
      load: () =>
        import(/* webpackChunkName: 'reset_password' */ './reset_password'),
    },
    {
      path: '/change-password',
      load: () =>
        import(/* webpackChunkName: 'change-password' */ './change-password'),
    },
    {
      path: '/email_confirmation/:token',
      load: () =>
        import(/* webpackChunkName: 'email-confirm' */ './email-confirm'),
    },
    {
      path: '/contact',
      load: () => import(/* webpackChunkName: 'contact' */ './contact'),
    },
    {
      path: '/mybookings',
      load: () => import(/* webpackChunkName: 'mybookings' */ './mybookings'),
    },
    {
      path: '/mybooking',
      load: () => import(/* webpackChunkName: 'mybookings' */ './mybookings'),
    },
    {
      path: '/retrieve_booking',
      load: () => import(/* webpackChunkName: 'mybookings' */ './mybookings'),
    },
    {
      path: '/loyalty',
      load: () => import(/* webpackChunkName: 'loyalty' */ './loyalty'),
    },
    {
      path: '/blog/:document?',
      load: () => import(/* webpackChunkName: 'blog' */ './blog'),
    },
    {
      path: '/blog/post/:slug',
      load: () => import(/* webpackChunkName: 'blog-post' */ './blog-post'),
    },
    {
      path: '/check-in/:bookingId',
      load: () =>
        import(/* webpackChunkName: 'check-in-home' */ './check-in-home'),
    },
    {
      path: '/check-in/:bookingId/review',
      load: () =>
        import(/* webpackChunkName: 'check-in-review' */ './check-in-review'),
    },
    {
      path: '/check-in/:bookingId/payment',
      load: () =>
        import(/* webpackChunkName: 'check-in-payment' */ './check-in-payment'),
    },
    {
      path: '/check-in/:bookingId/payment',
      load: () =>
        import(/* webpackChunkName: 'check-in-payment' */ './check-in-payment'),
    },
    {
      path: '/check-in/:bookingId/payment_success',
      load: () =>
        import(
          /* webpackChunkName: 'check-in-payment_success' */ './check-in-payment_success'
        ),
    },
    {
      path: '/check-in/:bookingId/guest_detail',
      load: () =>
        import(
          /* webpackChunkName: 'check-in-guest_detail' */ './check-in-guest_detail'
        ),
    },
    {
      path: '/check-in/:bookingId/guest_edit',
      load: () =>
        import(
          /* webpackChunkName: 'check-in-guest_edit' */ './check-in-guest_edit'
        ),
    },
    {
      path: '/check-in/:bookingId/guest_detail/issue_country',
      load: () =>
        import(
          /* webpackChunkName: 'check-in-guest_detail-issue_country' */ './check-in-guest_detail-issue_country'
        ),
    },
    {
      path: '/check-in/:bookingId/id_verification',
      load: () =>
        import(
          /* webpackChunkName: 'check-in-guest_id_verification' */ './check-in-guest_id_verification'
        ),
    },
    {
      path: '/check-in/:bookingId/room_preference',
      load: () =>
        import(
          /* webpackChunkName: 'check-in-room_preference' */ './check-in-room_preference'
        ),
    },
    {
      path: '/check-in/:bookingId/room_success',
      load: () =>
        import(
          /* webpackChunkName: 'check-in-room_success' */ './check-in-room_success'
        ),
    },
    {
      path: '/check-in/:bookingId/success',
      load: () =>
        import(/* webpackChunkName: 'check-in_success' */ './check-in_success'),
    },
    {
      path: '/check-in/:bookingId/pin',
      load: () =>
        import(/* webpackChunkName: 'check-in-pin' */ './check-in-pin'),
    },
    {
      path: '/api',
      load: () => import(/* webpackChunkName: 'api' */ './api'),
    },
    {
      path: '/health_check',
      load: () =>
        import(/* webpackChunkName: 'health_check' */ './health_check'),
    },
    // Wildcard routes, e.g. { path: '*', ... } (must go last)
    {
      path: '*',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next, store }) {
    // clear hotel info
    const state = store.getState();
    if (typeof state.hotel !== 'undefined' && state.hotel)
      await store.dispatch({ type: CLEAR_HOTEL_INFO });

    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `Red Planet Hotels | ${route.title || 'Untitled Page'}`;
    if (route.forcedTitle) {
      route.title = route.forcedTitle;
    }
    route.description = route.description || '';
    route.metaTitle = route.metaTitle || '';
    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
