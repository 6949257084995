/* eslint-disable no-shadow */

import {
  USER_INIT,
  USER_LOGIN,
  SUCCESS_USER_LOGIN,
  USER_LOGOUT,
  FAIL_USER_LOGIN,
  USER_SIGN_UP,
  SUCCESS_USER_SIGN_UP,
  FAIL_USER_SIGN_UP,
  USER_UPDATE,
  SUCCESS_USER_UPDATE,
  FAIL_USER_UPDATE,
  OPEN_SIGN_UP_MODAL,
  CLOSE_SIGN_UP_MODAL,
  OPEN_SIGN_IN_MODAL,
  CLOSE_SIGN_IN_MODAL,
  FORGOT_PASSWORD,
  FAIL_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  RESET_PASSWORD,
  FAIL_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  CHANGE_PASSWORD,
  FAIL_CHANGE_PASSWORD,
  SUCCESS_CHANGE_PASSWORD,
  NEW_PASSWORD,
  FAIL_NEW_PASSWORD,
  SUCCESS_NEW_PASSWORD,
  START_SUBSCRIBE_NEWS,
  SUCCESS_SUBSCRIBE_NEWS,
  FAIL_SUBSCRIBE_NEWS,
  GET_BOOKINGS_START,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAIL,
  OPEN_RESEND_BOOKING_MODAL,
  CLOSE_RESEND_BOOKING_MODAL,
  USER_BOOKING,
  INIT_CHANGE_PASSWORD,
  OPEN_NEWSLETTER_MODAL,
  CLOSE_NEWSLETTER_MODAL,
  OPEN_ERROR_MODAL,
  CLOSE_ERROR_MODAL,
  OPEN_DELETE_ACCOUNT_MODAL,
  CLOSE_DELETE_ACCOUNT_MODAL,
  OPEN_POPUP_MODAL,
  CLOSE_POPUP_MODAL,
} from '../constants';

export default function user(state = {}, action) {
  switch (action.type) {
    case USER_INIT: {
      const user = action.payload;
      return {
        ...user,
      };
    }
    case USER_LOGIN: {
      const { email, password } = action.payload;
      const errors = [];
      const errorMessage = '';
      const userLogin = {
        email,
        password,
      };
      return {
        ...state,
        userLogin,
        errors,
        errorMessage,
      };
    }
    case SUCCESS_USER_LOGIN: {
      const user = action.payload;
      const errors = [];
      const errorMessage = '';
      const isLoggedIn = true;
      const openSignUpModal = false;
      const openSignInModal = false;
      const openErrorModal = false;
      return {
        ...state,
        user: {
          ...user,
        },
        isLoggedIn,
        openSignUpModal,
        openSignInModal,
        openErrorModal,
        errors,
        errorMessage,
      };
    }
    case FAIL_USER_LOGIN: {
      const { errors, errorMessage } = action.payload;
      const isLoggedIn = false;
      return {
        ...state,
        isLoggedIn,
        errors,
        errorMessage,
      };
    }
    case USER_LOGOUT: {
      const errors = [];
      const errorMessage = '';
      const isLoggedIn = false;
      return {
        ...state,
        isLoggedIn,
        user: null,
        myBookings: null,
        errors,
        errorMessage,
      };
    }
    case USER_SIGN_UP: {
      const profile = action.payload;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        userSignUp: {
          ...profile,
        },
        errors,
        errorMessage,
      };
    }
    case SUCCESS_USER_SIGN_UP: {
      const profile = action.payload;
      const errors = [];
      const errorMessage = '';
      const isLoggedIn = true;
      const openSignUpModal = false;
      const openSignInModal = false;
      return {
        ...state,
        user: {
          ...profile,
        },
        isLoggedIn,
        openSignUpModal,
        openSignInModal,
        errors,
        errorMessage,
      };
    }
    case FAIL_USER_SIGN_UP: {
      const { errors, errorMessage } = action.payload;
      return {
        ...state,
        errors,
        errorMessage,
      };
    }
    case USER_UPDATE: {
      const profile = action.payload;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        updateProfile: {
          ...profile,
          success: false,
        },
        errors,
        errorMessage,
      };
    }
    case SUCCESS_USER_UPDATE: {
      const profile = action.payload;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        user: {
          ...profile,
        },
        updateProfile: {
          ...profile,
          success: true,
        },
        errors,
        errorMessage,
      };
    }
    case FAIL_USER_UPDATE: {
      const { errors, errorMessage } = action.payload;
      return {
        ...state,
        errors,
        errorMessage,
      };
    }
    case OPEN_DELETE_ACCOUNT_MODAL: {
      return {
        ...state,
        openDeleteAccountModal: true,
      };
    }
    case CLOSE_DELETE_ACCOUNT_MODAL: {
      return {
        ...state,
        openDeleteAccountModal: false,
      };
    }
    case OPEN_SIGN_IN_MODAL: {
      return {
        ...state,
        openSignInModal: true,
        openSignUpModal: false,
      };
    }
    case CLOSE_SIGN_IN_MODAL: {
      return {
        ...state,
        openSignInModal: false,
      };
    }
    case OPEN_SIGN_UP_MODAL: {
      return {
        ...state,
        openSignUpModal: true,
        openSignInModal: false,
      };
    }
    case CLOSE_SIGN_UP_MODAL: {
      return {
        ...state,
        openSignUpModal: false,
      };
    }
    case OPEN_NEWSLETTER_MODAL: {
      const { source } = action.payload;
      return {
        ...state,
        openNewsletterModal: true,
        newsLetterSource: source,
      };
    }
    case CLOSE_NEWSLETTER_MODAL: {
      return {
        ...state,
        openNewsletterModal: false,
      };
    }
    case OPEN_POPUP_MODAL: {
      // const { source } = action.payload;
      return {
        ...state,
        openPopupModal: true,
        // popupSource: source,
      };
    }
    case CLOSE_POPUP_MODAL: {
      return {
        ...state,
        openPopupModal: false,
      };
    }
    case OPEN_ERROR_MODAL: {
      return {
        ...state,
        openErrorModal: true,
      };
    }
    case CLOSE_ERROR_MODAL: {
      return {
        ...state,
        openErrorModal: false,
      };
    }
    case FORGOT_PASSWORD: {
      return {
        ...state,
        forgot: {
          isSubmitting: true,
          hasError: false,
          emailSent: false,
        },
      };
    }
    case FAIL_FORGOT_PASSWORD: {
      return {
        ...state,
        forgot: {
          isSubmitting: false,
          hasError: true,
          emailSent: false,
        },
      };
    }
    case SUCCESS_FORGOT_PASSWORD: {
      return {
        ...state,
        forgot: {
          isSubmitting: false,
          hasError: false,
          emailSent: true,
        },
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        reset: {
          isSubmitting: true,
          hasError: false,
          hasReset: false,
        },
      };
    }
    case FAIL_RESET_PASSWORD: {
      return {
        ...state,
        reset: {
          isSubmitting: false,
          hasError: true,
          hasReset: false,
        },
      };
    }
    case SUCCESS_RESET_PASSWORD: {
      return {
        ...state,
        reset: {
          isSubmitting: false,
          hasError: false,
          hasReset: true,
        },
      };
    }
    case INIT_CHANGE_PASSWORD: {
      return {
        ...state,
        change: {
          isSubmitting: false,
          hasError: false,
          hasChanged: false,
          errors: [],
        },
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        change: {
          isSubmitting: true,
          hasError: false,
          hasChanged: false,
          errors: [],
        },
      };
    }
    case FAIL_CHANGE_PASSWORD: {
      const { errors } = action.payload;
      return {
        ...state,
        change: {
          isSubmitting: false,
          hasError: true,
          hasChanged: false,
          errors,
        },
      };
    }
    case SUCCESS_CHANGE_PASSWORD: {
      return {
        ...state,
        change: {
          isSubmitting: false,
          hasError: false,
          hasChanged: true,
          errors: [],
        },
      };
    }
    case NEW_PASSWORD: {
      return {
        ...state,
        new_password: {
          isSubmitting: true,
          hasError: false,
          hasChanged: false,
          errors: [],
        },
      };
    }
    case FAIL_NEW_PASSWORD: {
      const { errors } = action.payload;
      return {
        ...state,
        new_password: {
          isSubmitting: false,
          hasError: true,
          hasChanged: false,
          errors,
        },
      };
    }
    case SUCCESS_NEW_PASSWORD: {
      return {
        ...state,
        new_password: {
          isSubmitting: false,
          hasError: false,
          hasChanged: true,
          errors: [],
        },
      };
    }
    case START_SUBSCRIBE_NEWS: {
      return {
        ...state,
        news: {
          starting: true,
          success: false,
        },
      };
    }
    case SUCCESS_SUBSCRIBE_NEWS: {
      return {
        ...state,
        news: {
          starting: false,
          success: true,
        },
      };
    }
    case FAIL_SUBSCRIBE_NEWS: {
      return {
        ...state,
        news: {
          starting: false,
          success: false,
        },
      };
    }
    case GET_BOOKINGS_START: {
      return {
        ...state,
        gettingBooking: true,
        myBookings: null,
        errors: [],
        errorMessage: '',
      };
    }
    case GET_BOOKINGS_SUCCESS: {
      const { myBookings } = action.payload;
      return {
        ...state,
        gettingBooking: false,
        myBookings,
      };
    }
    case GET_BOOKINGS_FAIL: {
      const { errors, errorMessage } = action.payload;
      return {
        ...state,
        gettingBooking: false,
        myBookings: null,
        errors,
        errorMessage,
      };
    }
    case OPEN_RESEND_BOOKING_MODAL: {
      const { bookingNumber, toEmail } = action.payload;
      return {
        ...state,
        resendBooking: {
          openResendBookingModal: true,
          bookingNumber,
          toEmail,
        },
      };
    }
    case CLOSE_RESEND_BOOKING_MODAL: {
      return {
        ...state,
        resendBooking: {
          openResendBookingModal: false,
          bookingNumber: '',
          toEmail: '',
        },
      };
    }
    case USER_BOOKING: {
      return {
        ...state,
        myBookings: null,
      };
    }
    default:
      return state;
  }
}
