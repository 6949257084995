import { HOTEL_INFO, CLEAR_HOTEL_INFO } from '../constants';

export default function hotel(state = null, action) {
  switch (action.type) {
    case HOTEL_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLEAR_HOTEL_INFO: {
      return null;
    }
    default: {
      return state;
    }
  }
}
