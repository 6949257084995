/**
 * Created by methee on 9/13/17.
 */

import {
  ADD_FLASH_MESSAGE,
  REMOVE_FLASH_MESSAGE,
  SHOW_OVERLAY_ONLY,
  CONFIRM_FLASH_MESSAGE,
  DISMISS_FLASH_MESSAGE,
} from '../constants';

export const TYPE_ERROR = 'TYPE_ERROR';
export const TYPE_OK = 'TYPE_OK';
export const TYPE_NOTICE = 'TYPE_NOTICE';
export const TYPE_ONLY_OVERLAY = 'TYPE_ONLY_OVERLAY';
export const TYPE_CONFIRM = 'TYPE_CONFIRM';

export function addFlashMessage(message, messageType) {
  let type = TYPE_NOTICE;
  if (messageType !== null) {
    type = messageType;
  }
  return async dispatch => {
    dispatch({
      type: ADD_FLASH_MESSAGE,
      payload: {
        message,
        type,
      },
    });
  };
}

export function removeFlashMessage() {
  return async dispatch => {
    await dispatch({
      type: DISMISS_FLASH_MESSAGE,
    });
    dispatch({
      type: REMOVE_FLASH_MESSAGE,
    });
  };
}

export function confirmFlashMessage() {
  return async dispatch => {
    await dispatch({
      type: CONFIRM_FLASH_MESSAGE,
    });
    await dispatch({
      type: REMOVE_FLASH_MESSAGE,
    });
  };
}

export function showOverlayOnly() {
  return async dispatch => {
    dispatch({
      type: SHOW_OVERLAY_ONLY,
    });
  };
}
